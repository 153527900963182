//==============================================================================
// Extremely simple shared state
//
// This version allows sharing of availability information across modules
//==============================================================================
import * as Msdyn365 from '@msdyn365-commerce/core';

//==============================================================================
//==============================================================================

//==========================================================
// Input Action
//==========================================================
export class SharedAvailabilityInput implements Msdyn365.IActionInput {

    //----------------------------------------------------------
    // Caching Stuff
    //----------------------------------------------------------
    public getCacheKey = () => `SharedAvailability`;
    public getCacheObjectType = () => 'SharedAvailability';
    public dataCacheType = (): Msdyn365.CacheType => 'request';
}

//==========================================================
//==========================================================
export function createInput(actionContext: Msdyn365.ICreateActionContext): Msdyn365.IActionInput {
    return new SharedAvailabilityInput();
}

//==========================================================
// Insanely simple class to hold a single variable
//==========================================================
export class SharedAvailability {
    public isAvailable: Boolean = true;
}

//==========================================================
//==========================================================
export async function sharedAvailabilityAction(input: SharedAvailabilityInput, ctx?: Msdyn365.IActionContext): Promise<SharedAvailability> {
    return new SharedAvailability();
}

//==============================================================================
//==============================================================================
export default Msdyn365.createObservableDataAction<SharedAvailability>({
    action: <Msdyn365.IAction<SharedAvailability>>sharedAvailabilityAction,
    input: createInput
});