import { IActionContext, ICoreContext, IDictionary } from '@msdyn365-commerce/core';
import { ICartState, ICartActionResult } from '@msdyn365-commerce/global-state';
import { CartLine, ProductAvailableQuantity } from '@msdyn365-commerce/retail-proxy';

//==============================================================================
// INTERFACES
//==============================================================================

export interface CartAttributeValues {
    '@odata.type': "#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue",
    Name: string,
    TextValue: string,
    TextValueTranslations: [],
    ExtensionProperties: []
}

//==============================================================================
// CLASS DEFINITION
//==============================================================================
/**
 * Util for edits to cart
 */
//==============================================================================
export class CartUtilities {

    protected actionContext: IActionContext;
    protected cart: ICartState;

    //==========================================================================
    // PUBLIC METHODS
    //==========================================================================

    //------------------------------------------------------
    // Constructor
    //------------------------------------------------------
    constructor(actionContext: IActionContext, cart: ICartState) {
        this.actionContext = actionContext;
        this.cart = cart;
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public static async editCartAttributeValues(cartState: ICartState, context: ICoreContext, attributeUpdates: IDictionary<string>): Promise<void> {
        if (!cartState || !cartState.cart) {
            context.telemetry.error('[CartUtilities.updateAttributeValues] Failed to load cart');
            return;
        }

        const currentAttributes = cartState.cart.AttributeValues || [];

        // Shallow clone so we don't modify the current internal reference
        const newAttributes = [...currentAttributes] as CartAttributeValues[];

        Object.keys(attributeUpdates).forEach(commerceKey => {
            const newValue = attributeUpdates[commerceKey];

            // Check to see if the attribute already exists
            const existingAttribute = newAttributes.find(entry => entry.Name === commerceKey);

            if (existingAttribute) {
                existingAttribute.TextValue = newValue;
            } else {
                newAttributes.push({
                    '@odata.type': '#Microsoft.Dynamics.Commerce.Runtime.DataModel.AttributeTextValue',
                    Name: commerceKey,
                    TextValue: newValue,
                    TextValueTranslations: [],
                    ExtensionProperties: []
                });
            }
        });

        await cartState.updateAttributeValues({newAttributeValues: newAttributes});
    }

    //------------------------------------------------------
    //------------------------------------------------------
    public static async addToExistingLine(
        cartState: ICartState,
        cartItemMatch: CartLine,
        context: ICoreContext,
        quantity: number,
        productAvailability: ProductAvailableQuantity | undefined
    ): Promise<ICartActionResult> {

        // Don't continue if QTY is greater then max quantity or product available
        const quantityLimit: number = context.request.app.config.maxQuantityForCartLineItem;
        const checkStock: number = context.request.app.config.enableStockCheck;
        const quantityCombined = (cartItemMatch.Quantity || 0) + (quantity);
        if (quantityLimit && quantityCombined > quantityLimit) {
            return { status: 'FAILED', substatus: 'MAXQUANTITY' };
        }
        if (checkStock && (!productAvailability || quantityCombined > (productAvailability.AvailableQuantity || 0))) {
            return { status: 'FAILED', substatus: 'MAXQUANTITY' };
        }

        // Update Quantity
        await cartState.updateCartLineQuantity({cartLineId: cartItemMatch.LineId!, newQuantity: quantityCombined});
        return { status: 'SUCCESS' };
    }
}